$(document).ready(function () {

  if ($('.hero-swiper')[0]) {
    var swiper = new Swiper('.hero-swiper', {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000
      },
      pagination: {
        clickable: true,
        el: '.hero-swiper__pagination',
        bulletClass: 'hero-swiper__pagination-bullet',
        modifierClass: 'hero-swiper__pagination-bullet--',
        bulletActiveClass: 'hero-swiper__pagination-bullet--active',
        currentClass: 'hero-swiper__pagination-bullet--current',
      },
      navigation: {
        nextEl: '.hero-swiper__button-next',
        prevEl: '.hero-swiper__button-prev'
      }
    });
  }

});

