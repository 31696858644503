$(document).ready(function () {

  if ($('.hamburger')[0]) {
    var menuButtonOpen = $("#menu__mobile-button-open");
    var menuButtonClose = $("#menu__mobile-button-close");
    var menu = $(".menu__mobile-content");
    var menuParent = $(".menu-item-has-children");

    menuButtonOpen.on("click", function (e) {
      menu.addClass('menu__mobile-content--active');
      menuButtonClose.addClass('is-active');
    });

    menuButtonClose.on("click", function (e) {
      menu.removeClass('menu__mobile-content--active');
      menuButtonClose.removeClass('is-active');
    });

    menuParent.on("click", function (e) {
      if ($(this).children('.sub-menu').hasClass('sub-menu--active')) {
        $(this).children('.sub-menu').removeClass('sub-menu--active');
        $(this).removeClass('active');
      } else {
        $(this).children('.sub-menu').addClass('sub-menu--active');
        $(this).addClass('active');
      }
    });



  }

  jQuery(function ($) { $("li.menu-item-has-children").children("a").attr('href', "javascript:void(0)"); });

});